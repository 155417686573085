import React, {useEffect, useState} from "react";

import {logger} from "../../../../utilities/logger/logger";

import config from "../../../../config";

//APIs
import {coinGetBalance} from "../../../../api/coinAPI";
import {crowdsaleGetReservationsOfAccount,} from "../../../../api/crowdsaleAPI";

//styles

//i18n
import {useTranslation} from "react-i18next";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    Typography,
    useTheme,
} from "@material-ui/core";

import SlideModal from "../../../../components/UI/Modal/SlideModal/SlideModal";
import PiggyBank from "./PiggyBank/PiggyBank";
import CoinAvatarLabeled from "../../../../components/UI/CoinAvatarLabeled/CoinAvatarLabeled";
import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {getUserNameByAddress, UserNameType} from "../../../../api/userAPI";
import ZoomModal from "../../../../components/UI/Modal/ZoomModal/ZoomModal";
import {crowdsaleStop} from "../../../../store/slices/crowdsaleSlice";
import Loading from "../../../../components/UI/Loading/Loading";

const crowdsaleStatusEnum = config.crowdsaleStatus;

//helper function to compute time left before ending of the crowdsale
const TimeStatusEnum = Object.freeze({
    started: "RUNNING",
    ended: "STOPPED",
    notStarted: "LOCKED",
});
const computeTimeLeft = (startDate: Date, endDate: Date) => {
    const today = new Date();
    if (startDate < today) {
        // start date already passed
        if (endDate > today) {
            // and end date not reached
            const timeLeft =
                (endDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24); //days left from end
            logger.debug("PiggiesDetails -> TIMELEFT", timeLeft);
            return {
                timeStatus: TimeStatusEnum.started,
                timeLeft: Math.round(timeLeft),
            };
        } else {
            //endDate already reached
            logger.debug("PiggiesDetails -> endDate not reached yet");
            return {
                timeStatus: TimeStatusEnum.ended,
                timeLeft: -1,
            };
        }
    } else {
        //startDate not reached yet
        logger.debug("PiggiesDetails -> startDate not reached yet");
        return {
            timeStatus: TimeStatusEnum.notStarted,
            timeLeft: -1,
        };
    }
};

const useStyles = makeStyles((theme) => ({
    showWarningText: {
        color: theme.palette.warning.main,
        display: "block",
    },
    doNotShowWarningText: {
        display: "none",
    },
    card: {
        overflow: "auto",
    },
    media: {
        objectFit: "cover",
        maxHeight: "350px",
    },
    actions: {
        display: "flex",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    avatar: {
        backgroundColor: red[500],
    },
    favorite: {
        marginLeft: 350,
        marginTop: -400,
    },
    end: {
        marginLeft: "auto",
    },
    center: {
        justifyContent: "center",
    },
    button: {},
    gridList: {
        width: 400,
        height: 410,
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    itemCenter: {
        textAlign: "center",
    }
}));

type PiggiesDetailsProps = {
    crowdsale: any;
    closePiggieDetails: () => void;
    reload: (mustReload: boolean) => void;
};

const PiggiesDetails = (props: PiggiesDetailsProps) => {
    const {t} = useTranslation("PiggiesDetails");
    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useAppDispatch();

    const [showLoadingComponent, setShowLoadingComponent] = useState(false);
    const stopLoading = useAppSelector((state) => state.crowdsale.stopLoading);
    const stopped = useAppSelector((state) => state.crowdsale.stopped);

    const {closePiggieDetails, crowdsale, reload} = props;

    const user = useAppSelector((state) => state.user.user);
    const dao = useAppSelector((state) => state.dao.currentDao);
    const crowdsales = useAppSelector((state) => state.crowdsale.crowdsales);
    const currentProfile = useAppSelector((state) => state.user.currentProfile);
    //const userWalletAddress=useAppSelector(state =>state.web3.currentAccount );
    const ethers = useAppSelector((state) => state.ethers.ethersInstance);
    const refunded = useAppSelector(state => state.crowdsale.refunded);
    const joined = useAppSelector(state => state.crowdsale.joined);

    logger.debug("[crowdsale for this piggiedetail =>", crowdsale);
    logger.info("crowdsale of this piggie =>", crowdsale);

    const [isPiggyBankOpen, setPiggyBankOpen] = useState(false);
    const [isStopConfirmationModalOpen, setStopConfirmationModalOpen] =
        useState(false);
    const [tokenToAcceptUserBalance, setTokenToAcceptUserBalance] = useState(0);
    const [userBalanceIsReady, setUserBalanceReady] = useState(false);
    const [userWalletReservations, setUserWalletReservations] = useState(0);
    const [areUserWalletReservationsLoaded, setUserWalletReservationsLoaded] =
        useState(false);
    const [ownerName, setOwnerName] = useState<UserNameType | undefined>();
    const [reloadAfterPiggyOperation, setReloadAfterPiggyOperation]= useState(false)

    async function getBalanceReservationsAndUserName() {
        if (currentProfile !== undefined && currentProfile !== null) {
            const currentProfileWallet =
                currentProfile.additional_properties?.commonshoodWallet;
            if (currentProfileWallet !== undefined && ethers !== null) {
                let response = await coinGetBalance(
                    ethers,
                    currentProfileWallet,
                    crowdsale.tokenToAcceptAddr
                );
                setTokenToAcceptUserBalance(response.balance);
                setUserBalanceReady(true);
                let reservations = await crowdsaleGetReservationsOfAccount(
                    ethers,
                    currentProfileWallet,
                    crowdsale.crowdsaleAddress
                );
                setUserWalletReservations(reservations);
                setUserWalletReservationsLoaded(true);
                const userName = await getUserNameByAddress(crowdsale.ownerAddress);
                setOwnerName(userName);
            }
        }
    }

    async function getReservations() {
        if (currentProfile !== undefined && currentProfile !== null) {
            const currentProfileWallet =
                currentProfile.additional_properties?.commonshoodWallet;
            if (currentProfileWallet !== undefined && ethers !== null) {
                let reservations = await crowdsaleGetReservationsOfAccount(
                    ethers,
                    currentProfileWallet,
                    crowdsale.crowdsaleAddress
                );
                setUserWalletReservations(reservations);
                setUserWalletReservationsLoaded(true);
            }
        }
    }

    async function getUserName() {
        const userName = await getUserNameByAddress(crowdsale.ownerAddress);
        setOwnerName(userName);
    }

    useEffect(() => {
        getBalanceReservationsAndUserName()
    }, []);


    useEffect(() => {
        if(reloadAfterPiggyOperation && (refunded || joined || stopped)){
            getBalanceReservationsAndUserName()
        }
        setReloadAfterPiggyOperation(true)
    }, [reloadAfterPiggyOperation, refunded, joined, stopped]);

    // This is just to avoid a race condition when closing the modal which removes the crowdsale passed as a
    // props but sometimes it does it before this component is unmounted causing a reference error
    if (!crowdsale) {
        return null;
    }

    let timeLeftComponent = null;
    const crowdsaleTime = computeTimeLeft(crowdsale.startDate, crowdsale.endDate);
    //let's show the time left only if the crowdsale has not already reached cap:
    if (crowdsale.totalReservations >= crowdsale.maxCap) {
        if (crowdsaleTime.timeStatus === TimeStatusEnum.ended) {
            timeLeftComponent = (
                <CardActions className={classes.actions}>
                    <Grid container alignContent="space-between" direction="row">
                        <Grid item xs={12} className={classes.itemCenter}>
                            <Icon className={classes.end}>alarm_on</Icon>
                            <Typography variant="caption">{t("crowdsaleEnded")}</Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            );
        } else if (
            crowdsaleTime.timeStatus === TimeStatusEnum.started &&
            crowdsaleTime.timeLeft < 1 //less than a day
        ) {
            timeLeftComponent = (
                <CardActions className={classes.actions}>
                    <Grid container alignContent="space-between" direction="row">
                        <Grid item xs={12} className={classes.itemCenter}>
                            <Icon className={classes.end}>alarm_on</Icon>
                            <Typography variant="caption">
                                {t("timeLeftLessThanADay")}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            );
        } else if (crowdsaleTime.timeStatus === TimeStatusEnum.started) {
            timeLeftComponent = (
                <CardActions className={classes.actions}>
                    <Grid container alignContent="space-between" direction="row">
                        <Grid item xs={12} className={classes.itemCenter}>
                            <Icon className={classes.end}>alarm_on</Icon>
                            <Typography variant="caption">
                                {t("timeLeft", {
                                    params: {days: Math.round(crowdsaleTime.timeLeft)},
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            );
        } else {
            //crowdsale already ended
            timeLeftComponent = (
                <CardActions className={classes.actions}>
                    <Icon className={classes.end}>alarm_on</Icon>
                    <Typography variant="caption">
                        {t("startingDateNotReachedYet")}
                    </Typography>
                </CardActions>
            );
        }
    }

    //managing user-warning text:
    let warningText = "";
    let warningTypographyClass = classes.doNotShowWarningText;
    if (
        crowdsale.status === crowdsaleStatusEnum[1] || //stopped
        crowdsale.status === crowdsaleStatusEnum[2] //locked
    ) {
        warningText = t("crowdsaleNotRunning");
        warningTypographyClass = classes.showWarningText;
    } else {
        //status running
        if (!crowdsale.isOwnedByCurrentUserWallet) {
            if (crowdsaleTime.timeStatus === TimeStatusEnum.notStarted) {
                warningText = t("startingDateNotReachedYet");
                warningTypographyClass = classes.showWarningText;
            } else {
                //cwd started
                if (crowdsaleTime.timeStatus === TimeStatusEnum.ended) {
                    if (areUserWalletReservationsLoaded && userWalletReservations > 0) {
                        //reservations left that the user can get back
                        warningText = t("crowdsaleEndedWithReservationLeft");
                        warningTypographyClass = classes.showWarningText;
                    } else {
                        //ended and user didn't joined
                        warningText = t("crowdsaleEnded");
                        warningTypographyClass = classes.showWarningText;
                    }
                } else if (
                    userBalanceIsReady &&
                    tokenToAcceptUserBalance < crowdsale.acceptRatio
                ) {
                    //not enough coin to join
                    warningText = t("notEnoughCoinToJoin", {
                        params: {ticker: crowdsale.tokenToAccept.symbol},
                    });
                    warningTypographyClass = classes.showWarningText;
                }
            }
        } else {
            // crowdsale is owned by the logged user
            warningText = t("crowdsaleOwned");
            warningTypographyClass = classes.showWarningText;
        }
    }

    //pledge button DEactivation conditions check
    // logger.info("1  === ", crowdsale.isOwnedByCurrentUserWallet);
    // logger.info("2  === ", !crowdsale.isOwnedByCurrentUserWallet &&
    //     crowdsaleTime.timeStatus !== TimeStatusEnum.started && userWalletReservations<=0);
    // logger.info("3  === ", !crowdsale.isOwnedByCurrentUserWallet &&
    //     crowdsaleTime.timeStatus === TimeStatusEnum.ended &&
    //     areUserWalletReservationsLoaded &&
    //     userWalletReservations <= 0);
    // logger.info("4  === ", !crowdsale.isOwnedByCurrentUserWallet &&
    //     userBalanceIsReady &&
    //     tokenToAcceptUserBalance < crowdsale.acceptRatio &&
    //     areUserWalletReservationsLoaded &&
    //     userWalletReservations <= 0);
    //====

    const handleStop = () => {
        dispatch(crowdsaleStop(crowdsale.crowdsaleAddress));
        setStopConfirmationModalOpen(false)
    };

    const pledgeButton = (
        <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            disabled={
                //disable this button if:
                // user is the owner, so cannot join
                crowdsale.isOwnedByCurrentUserWallet ||
                //not started or close or stopped
                (!crowdsale.isOwnedByCurrentUserWallet &&
                    crowdsaleTime.timeStatus !== TimeStatusEnum.started &&
                    userWalletReservations <= 0) ||
                //crowdsale ended and user has not still reservations on it
                (!crowdsale.isOwnedByCurrentUserWallet &&
                    crowdsaleTime.timeStatus === TimeStatusEnum.ended &&
                    areUserWalletReservationsLoaded &&
                    userWalletReservations <= 0) ||
                //we are still waiting to get the balance of the user for the tokenToAccept
                !userBalanceIsReady ||
                //the user has not enough tokenToAccept to join nor reservation to refund
                (!crowdsale.isOwnedByCurrentUserWallet &&
                    userBalanceIsReady &&
                    tokenToAcceptUserBalance < crowdsale.acceptRatio &&
                    areUserWalletReservationsLoaded &&
                    userWalletReservations <= 0)
            }
            onClick={() => {
                setPiggyBankOpen(true);
            }}
        >
            {t("pledge")}
        </Button>
    );
    logger.info(crowdsale.status === TimeStatusEnum.ended, crowdsaleTime.timeStatus, TimeStatusEnum.ended);

    const stopCrowdsale = (
        <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={crowdsale.status === TimeStatusEnum.ended || stopped}
            className={classes.button}
            onClick={() => {
                setStopConfirmationModalOpen(true);
            }}
        >
            {t("close")}
        </Button>
    );

    let localeStartingDate = `${crowdsale.startDate.toLocaleString()}`;
    let localeEndingDate = `${crowdsale.endDate.toLocaleString()}`;

    let goalReached = null;
    if (crowdsale.totalReservations <= crowdsale.maxCap) {
        goalReached = (
            <>
                <Icon className={classes.end}>trending_up</Icon>
                {t("goalReached", {
                    params: {
                        currentReservation: crowdsale.totalReservations,
                        threshold: crowdsale.maxCap,
                        ticker: crowdsale.tokenToAccept.symbol,
                    },
                })}
            </>
        );
    }
    // if we are over maxCap the remaining coin are not convertible in coupons anymore (coupons are over)
    // we use this value to allow the user to refund even at crowdsale over
    const maxJoinLeft =
        crowdsale.totalReservations >= crowdsale.maxCap
            ? 0
            : crowdsale.maxCap - crowdsale.totalReservations;

    const url = crowdsale.contractHash;
    const contractButton = (
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => window.open(url, "_blank")}
        >
            {t("downloadContract")}
        </Button>
    );

    let yourReservationMessage = null;
    if (
        !crowdsale.isOwnedByCurrentUserWallet &&
        areUserWalletReservationsLoaded
    ) {
        if (userWalletReservations > 0) {
            const params = {
                params: {
                    reservation: userWalletReservations,
                    ticker: crowdsale.tokenToAccept.symbol,
                },
            };
            yourReservationMessage = (
                <Typography
                    variant="overline"
                    align="center"
                    style={{display: "block"}}
                >
                    {t("youAlreadyJoined", params)}
                </Typography>
            );
        } else {
            yourReservationMessage = (
                <Typography
                    variant="overline"
                    align="center"
                    style={{display: "block"}}
                >
                    {t("youHaveNotJoinedYet")}
                </Typography>
            );
        }
    }

    const handleModalClose = () => {
        setShowLoadingComponent(false);
        closePiggieDetails();
        reload(true);
    };

    const loadingTransactionComponent = (
        <Loading title={t("crowdsaleStopMessageInfo")} withLoader/>
    );

    return (
        <>
            <Card square className={classes.card}>
                <CardMedia
                    component="img"
                    className={classes.media}
                    image={crowdsale.logoHash}
                    title={crowdsale.title}
                />
                {/*<Fab size="small" className={classes.favorite}>*/}
                {/*        <Icon color="error">favorite</Icon>*/}
                {/*        </Fab>*/}
                <CardContent  className={classes.itemCenter}>
                    <Typography paragraph>{crowdsale.description}</Typography>
                </CardContent>
                <CardActions className={classes.actions}>
                    <Grid container alignContent="space-between" direction="row">
                        <Grid item xs={12} sm={6}  className={classes.itemCenter}>
                            <Icon>calendar_today</Icon>
                            <Typography variant="caption">{`${t(
                                "starting"
                            )}: ${localeStartingDate}`}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}  className={classes.itemCenter}>
                            <Icon className={classes.end}>calendar_today</Icon>
                            <Typography variant="caption">{`${t(
                                "ending"
                            )}: ${localeEndingDate}`}</Typography>
                        </Grid>
                    </Grid>
                </CardActions>
                {timeLeftComponent}
                <CardActions className={classes.actions}>
                    <Grid container alignContent="space-between" direction="row">
                        {ownerName ? (
                            <Grid item xs={12} sm={6}  className={classes.itemCenter}>
                                <Typography>
                                    {t("owner")}:{" "}
                                    {ownerName.first_name + " " + ownerName.last_name}{" "}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} sm={6}  className={classes.itemCenter}>
                            <Typography className={classes.end}>{goalReached}</Typography>
                        </Grid>
                    </Grid>
                </CardActions>
                <CardActions className={classes.center}>{contractButton}</CardActions>
                <CardActions className={classes.center}>
                    <Typography>
                        <IconButton disabled={true}>
                            <CoinAvatarLabeled
                                noName={true}
                                coin={{
                                    symbol: crowdsale.tokenToAccept.symbol,
                                    logoFile: crowdsale.tokenToAcceptLogo,
                                }}
                            />
                            <Typography variant="caption">
                                {crowdsale.acceptRatio + " " + crowdsale.tokenToAccept.symbol}
                            </Typography>
                        </IconButton>
                        <Icon>compare_arrows</Icon>
                        <IconButton disabled={true}>
                            <CoinAvatarLabeled
                                noName={true}
                                coin={{
                                    symbol: crowdsale.tokenToGive.symbol,
                                    logoFile: crowdsale.tokenToGiveLogo,
                                }}
                            />
                            <Typography variant="caption">
                                {parseInt(crowdsale.giveRatio) +
                                    " " +
                                    crowdsale.tokenToGive.symbol}
                            </Typography>
                        </IconButton>
                    </Typography>
                </CardActions>
                {yourReservationMessage}
                <Typography
                    variant="caption"
                    align="center"
                    className={warningTypographyClass}
                >
                    {warningText}
                </Typography>
                <CardActions className={classes.center}>
                    {!crowdsale.isOwnedByCurrentUserWallet ? pledgeButton : null}
                </CardActions>
                <CardActions className={classes.center}>
                    {crowdsale.isOwnedByCurrentUserWallet ? stopCrowdsale : null}
                </CardActions>
                {/* <CardActions className={classes.actions} disableActionSpacing>
                        <IconButton aria-label="Add to favorites">
                        <FavoriteIcon color="error" />
                        </IconButton>
                        <Typography variant="caption">{t('favorites')}</Typography>
                        <IconButton disabled={true} aria-label="Share" className={classes.end}>
                        <ShareIcon color="primary" />
                        </IconButton>
                        <Typography variant="caption">{t('share')}</Typography>
                    </CardActions> */}
            </Card>

            <SlideModal
                open={isPiggyBankOpen}
                handleClose={() => {
                    setPiggyBankOpen(false);
                    //closePiggieDetails();
                }}
                title={t("partecipate")}
            >
                <PiggyBank
                    reload={()=>{
                        getBalanceReservationsAndUserName()
                    }}
                    piggyBankClose={() => {
                        setPiggyBankOpen(false);
                        // closePiggieDetails();
                        reload(true);
                    }}
                    crowdsale={crowdsale}
                    crowdsaleEnded={crowdsaleTime.timeStatus !== crowdsaleStatusEnum[0]}
                    tokenToAcceptUserBalance={tokenToAcceptUserBalance}
                    startingReservation={crowdsale.totalReservations}
                    maxJoinLeft={maxJoinLeft}
                    userReservations={userWalletReservations}
                />
            </SlideModal>
            <ZoomModal
                title={"STOP"}
                fullscreen={"false"}
                open={isStopConfirmationModalOpen}
            >
                <Typography> Sicuro di voler chiudere la crowdsale?</Typography>
                <Grid container>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{marginTop: "1em"}}
                            onClick={() => setStopConfirmationModalOpen(false)}
                        >
                            {"NO"}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{marginTop: "1em"}}
                            onClick={() => handleStop()}
                        >
                            {t("proceed")}
                        </Button>
                    </Grid>
                </Grid>
            </ZoomModal>
            {
                stopLoading ?  <Grid container item justifyContent='center'>
                    <CircularProgress color="primary"/>
                </Grid> : null
            }
            {/*<ZoomModal*/}
            {/*  title={t("crowdsaleStopMessage")}*/}
            {/*  fullscreen={"false"}*/}
            {/*  open={showLoadingComponent}*/}
            {/*>*/}
            {/*  {stopLoading ? (*/}
            {/*    loadingTransactionComponent*/}
            {/*  ) : stopped ? (*/}
            {/*    <AlertAvatar big success text={t("crowdsaleStopSuccess")} />*/}
            {/*  ) : (*/}
            {/*    <AlertAvatar big fail text={t("crowdsaleStopFail")} />*/}
            {/*  )}*/}
            {/*  <Button*/}
            {/*    disabled={stopLoading}*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    style={{ marginTop: "1em" }}*/}
            {/*    onClick={handleModalClose}*/}
            {/*  >*/}
            {/*    {"OK"}*/}
            {/*  </Button>*/}
            {/*</ZoomModal>*/}
        </>
    );
};

export default PiggiesDetails;
