import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//i18n
import { useTranslation } from "react-i18next";

import {
  CoinSendData,
  getTokensOwnedFM,
  getTokenWithBalanceFM,
} from "../../../../store/slices/coinSlice";
import {
  coinGetList,
  coinSend,
  coinUnsetPreselected,
} from "../../../../store/slices/coinSlice";
import type { UserData } from "../../../../types/user.type";
import type { Place } from "../../../../types/place.type";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

//Validation schema
import { Formik, FormikValues } from "formik";
import getValidationSchema from "./getValidationSchema";

//Async material components
import { Button, Grid, Typography } from "@material-ui/core";

//Custom components
import Loading from "../../../../components/UI/Loading/Loading";
import SplashScreen from "../../../../components/UI/SplashScreen/SplashScreen";
import SelectForm from "./SelectForm/SelectForm";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import ZoomModal from "../../../../components/UI/Modal/ZoomModal/ZoomModal";
import { logger } from "../../../../utilities/logger/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
}));

type CoinSendFormType = {
  contact: UserData | Place;
  handleClose: () => void;
  preselectedCoin: any | null; //fixme type
};

const CoinSendForm = (props: CoinSendFormType) => {
  const { contact, handleClose, preselectedCoin } = props;

  const { t } = useTranslation(["CoinSendForm", "Common"]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const coinSendLoading = useAppSelector((state) => state.coin.coinSendLoading);
  const coinSent = useAppSelector((state) => state.coin.coinSent);
  //const coinSentMining = useAppSelector(state => state.coin.coinSentMining);
  const coinError = useAppSelector((state) => state.coin.error);
  const coinList = useAppSelector((state) => state.coin.coinList);
  const profile = useAppSelector((state) => state.user.currentProfile);
  const getListloading = useAppSelector((state) => state.coin.getListloading);
  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);
  const [coinData, setCoinData] = useState<CoinSendData | null>(null);

  useEffect(() => {
    //Load coin list from current profile
    if (!getListloading && profile) {
      if (!preselectedCoin) {
        dispatch(getTokensOwnedFM("", false, false, -1));
        //dispatch(coinGetList(null, false, true, false, null));
      } else {
        //dispatch(coinGetList(null, false, true, false, [preselectedCoin.address]))
        dispatch(getTokenWithBalanceFM(preselectedCoin.address, undefined));
      }
    }
  }, []);

  const handleSendClick = (values: FormikValues) => {
    const coinData: CoinSendData = {
      symbol: values.selectedCoin,
      amount: values.amount,
      decimals: values.decimals,
      recipient: contact,
    };
    logger.info(coinData);
    setCoinData(coinData);
    setConfirmationModalOpened(true);
  };

  const handleSend = () => {
    if (coinData) {
      setConfirmationModalOpened(false);
      dispatch(coinSend(coinData));
      dispatch(coinUnsetPreselected());
      handleClose();
    }
  };

  //Redirect to coin creation in case there's no coin in wallet
  const handleCreateButton = () => {
    history.push("/coinCreate?firstTime");
  };

  //Waiting for contact to be loaded
  let form;

  if (contact) {
    if (getListloading) {
      form = <Loading withLoader title={t("Common:loadingForm")} />;
    } else if (coinList && coinList.length !== 0) {
      // There are coins in wallet
      form = (
        <Formik
          initialValues={{
            selectedCoin: undefined,
            amount: 0,
            selectedBalance: 0,
            decimals: 0,
          }}
          onSubmit={(values, actions) => {
            handleSendClick(values);
            actions.setSubmitting(false);
          }}
          validationSchema={getValidationSchema(t)}
          render={(props) => <SelectForm {...props} />}
        />
      );

      // else if (waitingForSendResult) {
      //     form = (
      //         <div style={{
      //             display: 'flex',
      //             justifyContent: 'center',
      //             alignItems: 'center'
      //         }}>
      //             <AlertAvatar
      //                 style={{marginLeft: 'auto', marginRight: 'auto'}}
      //                 big
      //                 success={coinSent}
      //                 fail={coinError}/>
      //             <Typography variant="subtitle1">{coinSent
      //                 ? t('paymentSent')
      //                 : coinError ? t('paymentError') + coinError
      //                     : null}</Typography>
      //         </div>
      //     )
      // }
    } else if (coinList && coinList.length === 0) {
      //There is no coin in wallet to send
      form = (
        <SplashScreen
          title={t("noCoinTitle")}
          avatarProps={{ big: true, warning: true }}
        >
          <div>{t("doYouWantToCreateCoin")}</div>
          <div>
            <Button
              className={classes.buttons}
              variant="contained"
              onClick={handleCreateButton}
            >
              {t("createNewCoin")}
            </Button>
          </div>
        </SplashScreen>
      );
    }
  }

  // Render form page
  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        {form}
      </Grid>
      <ZoomModal
        title={"SEND"}
        fullscreen={"false"}
        open={confirmationModalOpened && coinData}
      >
        <Typography>
          {" "}
          Sicuro di voler inviare{" "}
          {coinData ? coinData!.amount + " " + coinData.symbol : ""}?
        </Typography>
        <Typography color="secondary">
          {" "}
          Riceverai una notifica al termine dell'operazione
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "1em" }}
              onClick={() => setConfirmationModalOpened(false)}
            >
              {"NO"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "1em" }}
              onClick={() => handleSend()}
            >
              {"Procedi"}
            </Button>
          </Grid>
        </Grid>
      </ZoomModal>
    </Grid>
  );
};

export default CoinSendForm;
