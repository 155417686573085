import Map from 'ol/Map'
import React, { useEffect, useState } from 'react'
import TileLayer from 'ol/layer/Tile'
import { XYZ } from 'ol/source'
import { View } from 'ol'
import { transform } from 'ol/proj'
import { defaults } from 'ol/control'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import '../../theme/map.css'
import {
    contactSample,
    DaoContact,
    layer,
    positionFeature,
    positionLayer,
    vtLayer,
    placesVectorSource,
    CrwdslCardType,
    crwdslCardSample,
} from './MapUtils'
import { getPlacesDetailsByCoordinates } from '../../store/slices/placeSlice'
import Overlay from 'ol/Overlay'
import icon from '../../assets/img/mapImgUtils/home-green.png'
import Point from 'ol/geom/Point'
import { capitalCase } from 'change-case'
import CoinSendForm from '../../containers/Coin/Send/CoinSendForm/CoinSendForm'
import SlideModal from '../UI/Modal/SlideModal/SlideModal'
import { useTranslation } from 'react-i18next'
import { logger } from '../../utilities/logger/logger'
import {
    Grid,
    IconButton,
    ImageList,
    Theme,
    Typography,
} from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ClusterListCard from './Cards/ClusterListCard'
import CardDetails from './Cards/CardDetails'
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed'
import config from '../../config'
import CrowdsaleCard from './Cards/CrowdsaleCard'
import { daoGetListBelongingToLoggedUserStart } from '../../store/slices/daoSlice'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootScrollContainer: {
            position: 'absolute',
            bottom: 0,
            borderRadius: 20,
            backgroundColor: 'rgba(0,0,0,0.2)',
            marginBottom: '2vh',
            display: 'flex',
        },
        rootScroll: {
            padding: '2vh',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            marginLeft: '1vh',
        },
        imageList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS. [source: Material UI]
            transform: 'translateZ(0)',
        },
        title: {
            color: 'white',
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
        root: {
            display: 'flex',
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '100%',
            borderRadius: 20,
            marginBottom: '2vh',
            marginLeft: '2vh',
            marginRight: '2vh',
        },
        image: {
            width: 300,
            height: 200,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        iconButton: {
            color: 'white',
        },
        centerControl: {
            position: 'absolute',
            top: '1.4vh',
            right: '2vh',
            backgroundColor: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        centerControlIcon: {
            color: 'rgb(228,78,60)',
            '&:hover': {
                color: 'white',
            },
            fontSize: '2.5rem',
        },
    })
)

type MapPropsType = {
    preselectedCoin: null | any
    handleClose: () => void
}

const TSMap = (props: MapPropsType) => {
    const { preselectedCoin, handleClose } = props
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const { t } = useTranslation(['Common'])
    const [currentPosition, setCurrentPosition] = useState<any>([
        config.defaultGeoCoordinates.latitude,
        config.defaultGeoCoordinates.longitude,
    ])
    const [map, setMap] = useState<Map | null>(null)
    const mapDaoPlaces = useAppSelector(
        (state) => state.place.mapDaoPlacesByCoordinates
    )
    const mapCrwdslPlaces = useAppSelector(
        (state) => state.place.mapCrwdslPlacesByCoordinates
    )
    const [contact, setContact] = useState<DaoContact>(contactSample)
    const [clusterContacts, setClusterContacts] = useState<
        (DaoContact | CrwdslCardType)[]
    >([])
    const [sendFormOpen, setSendFormOpen] = useState(false)
    const [openDetails, setOpenDetails] = useState(false)
    const [openClusterDetails, setOpenClusterDetails] = useState(false)

    const [crwdslCard, setCrwdslCard] =
        useState<CrwdslCardType>(crwdslCardSample)
    const [openCrwdslDetails, setOpenCrwdslDetails] = useState(false)

    useEffect(() => {
        if (!map) {
            loadMap()
        }
    }, [])

    useEffect(() => {
        if (map) {
            centerControl()
        }
    }, [map])

    useEffect(() => {
        if (map && (mapDaoPlaces.length > 0 || mapCrwdslPlaces.length > 0)) {
            createFeatures()
            map.un('click', clickHandler)
            createOverlays()
            createListeners()
        }
    }, [mapDaoPlaces])

    const createFeatures = async () => {
        let daoFeatures: any[] = []
        let crwdslFeatures: any[] = []
        for (const flPlace of mapDaoPlaces) {
            let place = flPlace.place
            let daoAddress = flPlace.daoAddress
            if (place.id !== null && daoAddress !== null) {
                const format = placesVectorSource.getFormat()
                if (format) {
                    const feat = format.readFeature(place, {
                        featureProjection: 'EPSG:3857',
                    })
                    daoFeatures.push(feat)
                }
            }

            placesVectorSource.addFeatures(daoFeatures)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('ownerName', flPlace.owner)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('daoAddress', daoAddress)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('daoPlaceAddress', flPlace.address)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('resources', flPlace.resources)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('daoDetails', flPlace.daoDetails)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('placeRealm', 'dao')
        }
        for (const flPlace of mapCrwdslPlaces) {
            let place = flPlace.place
            let crwdslAddress = flPlace.place.web3.web3_address
            if (place.id !== null && crwdslAddress !== null) {
                const format = placesVectorSource.getFormat()
                if (format) {
                    const feat = format.readFeature(place, {
                        featureProjection: 'EPSG:3857',
                    })
                    crwdslFeatures.push(feat)
                }
            }
            placesVectorSource.addFeatures(crwdslFeatures)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('placeRealm', 'crowdsale')
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('owner', flPlace.owner)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('title', flPlace.title)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('description', flPlace.description)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('logoHash', flPlace.logoHash)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('start', flPlace.start)
            placesVectorSource.getFeatureById(place.id)?.set('end', flPlace.end)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('tokenToAccept', flPlace.tokenToAcceptAddr)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('tokenToGive', flPlace.tokenToGiveAddr)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('status', flPlace.status)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('props', place.properties)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('crwdslAddress', flPlace.crwdslAddress)
            placesVectorSource
                .getFeatureById(place.id)
                ?.set('ownerData', flPlace.ownerData)
        }
    }

    const createOverlays = () => {
        if (map) {
            const cardDOMElement = document.getElementById('overlay-card')
            const cardElement =
                cardDOMElement === null ? undefined : cardDOMElement
            let overlay: Overlay = new Overlay({
                element: cardElement,
                id: 'card-overlay',
            })
            map.addOverlay(overlay)

            const clusterDOMElement = document.getElementById('cluster-card')
            const clusterElement =
                clusterDOMElement === null ? undefined : clusterDOMElement
            let clusterOverlay: Overlay = new Overlay({
                element: clusterElement,
                id: 'cluster-overlay',
            })
            map.addOverlay(clusterOverlay)
        }
    }

    const createListeners = () => {
        if (map) {
            const listener = map.getListeners('click')
            if (listener === undefined) {
                map.on('click', clickHandler)
            }
        }
    }

    const resetView = () => {
        if (map) {
            setOpenDetails(false)
            setOpenClusterDetails(false)
            setOpenCrwdslDetails(false)

            setContact(contactSample)
            if (clusterContacts && clusterContacts.length > 0) {
                setClusterContacts([])
            }
            setCrwdslCard(crwdslCardSample)
        }
    }

    const centerControl = () => {
        if (map) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((location) => {
                    let position = transform(
                        [location.coords.longitude, location.coords.latitude],
                        'EPSG:4326',
                        'EPSG:3857'
                    )
                    positionFeature.setGeometry(new Point(position))
                    map.getView().animate({
                        center: position,
                        zoom: 15,
                        duration: 1500,
                    })
                    setCurrentPosition(position)
                })
            } else {
                logger.info('Geolocation is not supported by this browser.')
                setCurrentPosition(currentPosition)
            }
        }
    }

    const clickHandler = (event: any) => {
        if (map) {
            resetView()
            map.forEachFeatureAtPixel(event.pixel, async (feature, layer) => {
                if (feature.getProperties().features) {
                    if (feature.getProperties().features.length > 1) {
                        logger.info(feature.getProperties().features)
                        let contacts: (DaoContact | CrwdslCardType)[] = []
                        for (const feat of feature.getProperties().features) {
                            if (feat.values_.placeRealm === 'dao') {
                                let contact: DaoContact = {
                                    firstLifePlaceID: feat.id_,
                                    realm: feat.values_.placeRealm,
                                    icon: icon,
                                    name: feat.values_.name,
                                    additional_properties: {
                                        commonshoodWallet:
                                            feat.values_.daoAddress,
                                    },
                                    placeOwner: feat.values_.ownerName,
                                    daoPlaceAddress:
                                        feat.values_.daoPlaceAddress,
                                    daoDetails: feat.values_.daoDetails,
                                    resources: feat.values_.resources,
                                }
                                contacts.push(contact)
                            } else if (
                                feat.values_.placeRealm === 'crowdsale'
                            ) {
                                let crwdslCard: CrwdslCardType = {
                                    firstLifePlaceID: feat.id_,
                                    realm: feat.values_.placeRealm,
                                    title: feat.values_.title,
                                    description: feat.values_.description,
                                    logoHash: feat.values_.logoHash,
                                    start: feat.values_.start,
                                    end: feat.values_.end,
                                    tokenToAcceptAddr:
                                        feat.values_.tokenToAccept,
                                    tokenToGiveAddr: feat.values_.tokenToGive,
                                    status: feat.values_.status,
                                    crwdslAddress: feat.values_.crwdslAddress,
                                    owner: feat.values_.owner,
                                    ownerData: feat.values_.ownerData,
                                    props: feat.values_.props,
                                }
                                contacts.push(crwdslCard)
                            }
                        }
                        logger.info(contacts)
                        setClusterContacts(contacts)
                        setOpenClusterDetails(true)
                    } else {
                        let feat = feature.getProperties().features[0]
                        logger.info(feat)
                        if (feat.values_.placeRealm === 'dao') {
                            let contact: DaoContact = {
                                firstLifePlaceID: feat.id_,
                                realm: feat.values_.placeRealm,
                                icon: icon,
                                name: feat.values_.name,
                                additional_properties: {
                                    commonshoodWallet: feat.values_.daoAddress,
                                },
                                placeOwner: feat.values_.ownerName,
                                daoPlaceAddress: feat.values_.daoPlaceAddress,
                                daoDetails: feat.values_.daoDetails,
                                resources: feat.values_.resources,
                            }
                            setContact(contact)
                            setOpenDetails(true)
                        } else if (feat.values_.placeRealm === 'crowdsale') {
                            let crwdslCard: CrwdslCardType = {
                                firstLifePlaceID: feat.id_,
                                realm: feat.values_.placeRealm,
                                title: feat.values_.title,
                                description: feat.values_.description,
                                logoHash: feat.values_.logoHash,
                                start: feat.values_.start,
                                end: feat.values_.end,
                                tokenToAcceptAddr: feat.values_.tokenToAccept,
                                tokenToGiveAddr: feat.values_.tokenToGive,
                                status: feat.values_.status,
                                crwdslAddress: feat.values_.crwdslAddress,
                                owner: feat.values_.owner,
                                ownerData: feat.values_.ownerData,
                                props: feat.values_.props,
                            }
                            setCrwdslCard(crwdslCard)
                            setOpenCrwdslDetails(true)
                        }
                    }
                }
            })
        }
    }

    const loadMap = () => {
        const map = new Map({
            controls: defaults().extend([]),
            target: 'commonshood-map',

            layers: [
                new TileLayer({
                    source: new XYZ({
                        url:
                            config.network.firstLifeApi.tile_server +
                            config.network.firstLifeApi.mapbox_apiKey,
                    }),
                }),
                layer,
                vtLayer,
                positionLayer,
            ],
            view: new View({
                center: currentPosition,
                zoom: 14,
                minZoom: 6,
                maxZoom: 17,
                enableRotation: false,
            }),
        })

        map.on('moveend', () => {
            const bbox = map.getView().calculateExtent(map.getSize())
            let sw = transform([bbox[2], bbox[3]], 'EPSG:3857', 'EPSG:4326')
            let ne = transform([bbox[0], bbox[1]], 'EPSG:3857', 'EPSG:4326')
            dispatch(getPlacesDetailsByCoordinates(ne[1], ne[0], sw[1], sw[0]))
        })

        setMap(map)
    }

    const handleOpenDaoDetails = (contact: DaoContact) => {
        setContact(contact)
        setOpenDetails(true)
        setOpenClusterDetails(false)
    }

    const handleOpenCrowdsaleDetails = (contact: CrwdslCardType) => {
        setCrwdslCard(contact)
        setOpenCrwdslDetails(true)
        setOpenClusterDetails(false)
    }

    return (
        <>
            <SlideModal
                fullscreen={true}
                open={sendFormOpen}
                handleClose={() => {
                    setSendFormOpen(false)
                }}
                title={capitalCase(t('pay')) + ' ' + contact.name}
                icon={contact.icon}
            >
                <CoinSendForm
                    contact={contact}
                    handleClose={() => {
                        setSendFormOpen(false)
                        handleClose()
                    }}
                    preselectedCoin={preselectedCoin}
                />
            </SlideModal>

            <Grid
                id='commonshood-map'
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    overflowY: 'hidden',
                }}
            >
                {map && (
                    <IconButton
                        size={'small'}
                        className={classes.centerControl}
                        onClick={() => centerControl()}
                    >
                        <GpsNotFixedIcon
                            className={classes.centerControlIcon}
                        />
                    </IconButton>
                )}
                {openDetails && (
                    <CardDetails
                        closeCard={() => setOpenDetails(false)}
                        setSendFormOpen={setSendFormOpen}
                        contact={contact}
                    />
                )}
                {openCrwdslDetails && (
                    <CrowdsaleCard
                        contact={crwdslCard}
                        closeCard={() => setOpenCrwdslDetails(false)}
                    />
                )}
                {openClusterDetails && (
                    <Grid container className={classes.rootScrollContainer}>
                        <Grid className={classes.rootScroll}>
                            <ImageList className={classes.imageList}>
                                {clusterContacts.map((item) => (
                                    <ClusterListCard
                                        contact={item}
                                        handleOpenDaoDetails={
                                            (dao: DaoContact)=> {handleOpenDaoDetails(dao)}
                                        }
                                        handleOpenCrowdsaleDetails={(crowdsale: CrwdslCardType)=>{handleOpenCrowdsaleDetails(crowdsale)}}
                                    />
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default TSMap
