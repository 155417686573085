import React, {MouseEvent, useEffect, useState} from 'react';

import {AppBar, Avatar, Chip, CircularProgress, Hidden, Icon, IconButton, Toolbar, Typography} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';

import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';


import place_placeholder from "../../../../assets/img/mapImgUtils/home-green.png";
//Custom components
import NotificationBell from "../../../RealTimeNotification/NotificationBell";


const drawerWidth = 240;
const useStyle = makeStyles((theme: Theme) => ({
    title: {
        flexGrow: 1
    },
    // mainAppBar: {
    //     display: 'flex',
    //     position: 'absolute',
    //     zIndex: theme.zIndex.drawer +1
    // },
    mainAppBar: {
        //position: "relative",

        [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        }
    },
    avatar: {
        vertical: 'top',
        horizontal: 'right'
    },
    chip: {
        color: 'secondary'
    }
}));


type MainAppBarProps = {
    title: string;
    drawerHandler: () => void;
    profile: any; //fixme
};

const MainAppBar = (props: MainAppBarProps) => {
    const {
        title,
        drawerHandler,
        profile,
    } = props;

    const theme = useTheme();
    const classes = useStyle(theme);

    const [anchorEl, setAnchorEl] = useState<EventTarget & Element | null>(null);

    const handleMenu = (event:MouseEvent) => {
        setAnchorEl( event.currentTarget );
    };
    const handleClose = () => {
        setAnchorEl( null );
    };

    let avatar = place_placeholder;
    if (profile && profile.realm === "user")
        avatar = profile.avatar;

    return (
        <AppBar className={classes.mainAppBar}>
            <Toolbar>
                <Hidden smUp>
                    <IconButton
                        color="inherit"
                        aria-label="Menu"
                        onClick={drawerHandler}
                    >
                        <Icon>menu</Icon>
                    </IconButton>
                </Hidden>

                <Avatar src={avatar}/>
                <Typography variant="h6" color="inherit" className={classes.title}>
                    {<span>&nbsp;</span>}{title}
                </Typography>
                <NotificationBell anchorEl={anchorEl} handleMenu={handleMenu} onClose={handleClose}/>
                <Chip
                    size="medium"
                    icon={<BuildIcon className={classes.chip}/>}
                    label="ETHERS"
                    className={classes.chip}
                />

            </Toolbar>
        </AppBar>

    );

}

export default MainAppBar;
