import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { logger } from "../../../utilities/logger/logger";

//Redux actions connector
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  coinGetList,
  coinGetListReset,
  getTokensOwnedFM,
} from "../../../store/slices/coinSlice";
import {
  collectionGetList,
  collectionGetListReset,
  collectionResetSelected,
  nftGetListReset,
} from "../../../store/slices/nftSlice";

//Material UI components
import { Button, Divider, Tab, Tabs } from "@material-ui/core";

//i18n
import { useTranslation } from "react-i18next";

//Assets
import { assetsType } from "../../../config/assets";

//Custom Components
import CoinList from "../../../components/Coin/CoinList/CoinList";
import SlideModal from "../../../components/UI/Modal/SlideModal/SlideModal";
import Loading from "../../../components/UI/Loading/Loading";
import SplashScreen from "../../../components/UI/SplashScreen/SplashScreen";
import MyWalletDetails from "./MyWalletDetails/MyWalletDetails";

import CollectionList from "../../Nft/NftList/CollectionList/CollectionList";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import NftList from "../../Nft/NftList/NftList";
import { collectionProps } from "../../../types/nft.types";

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[200],
    margin: theme.spacing(1),
  },
}));

const MyWallet = () => {
  const { t } = useTranslation(["MyWallet", "NftCreateForm"]);
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [coinModalOpened, setCoinModalOpened] = useState<boolean>(false);
  const [assetType, setAssetType] = useState<string>(assetsType.token.name);
  const [selectedCoin, setSelectedCoin] = useState<any>(null); //fixme type

  const coinList: any[] = useAppSelector((state) => state.coin.coinList); //TODO fix type
  const profile = useAppSelector((state) => state.user.currentProfile);
  const coinLoading = useAppSelector((state) => state.coin.getListloading);
  const nftLoading = useAppSelector((state) => state.nft.loading);
  const page = useAppSelector((state) => state.nft.page);
  const currentProfile = useAppSelector((state) => state.user.currentProfile);
  const collectionList: collectionProps[] = useAppSelector(
    (state) => state.nft.collectionList
  );
  const nftList: any[] = useAppSelector((state) => state.nft.nftList);
  const collectionSelected: collectionProps | null = useAppSelector(
    (state) => state.nft.collectionSelected
  );

  logger.info(coinList)
  logger.info(nftList)

  //Redirect to coin creation in case there's no coin in wallet
  const handleCreateButton = () => {
    history.push("/coinCreate?firstTime");
  };
  /* Obsolete
    //Filter between Assets
    const handleFilter = () => {
        const changedAssetType = assetType === 'token' ?  assetsType.goods.name : assetsType.token.name;
        setAssetType(changedAssetType)
        dispatch(coinGetListReset())
        dispatch(coinGetList(changedAssetType,false,true,false,null));
    };*/
  // tabs handleChange
  const handleChange = (event: any, newValue: string) => {
    setAssetType(newValue);
  };
  // Opens the page of the selected asset
  useEffect(() => {
    dispatch(coinGetListReset());
    dispatch(collectionGetListReset());
    dispatch(nftGetListReset());
    dispatch(collectionResetSelected());
    if (assetType === "token") {
      dispatch(getTokensOwnedFM(assetsType.token.name, false, false, -1));
      //dispatch(coinGetList(assetsType.token.name, false, true, false, null));
    } else if (assetType === "goods") {
      dispatch(getTokensOwnedFM(assetsType.goods.name, false, false, -1));
      //dispatch(coinGetList(assetsType.goods.name, false, true, false, null));
    } else if (assetType === "nft") {
      dispatch(collectionGetList());
    }
  }, [assetType]);

  // Opens Coin Detail
  const coinDetailOpen = (symbol: string) => {
    const currentCoin = coinList.find((item) => item.symbol === symbol);
    setSelectedCoin(currentCoin);
    setCoinModalOpened(true);
  };

  //Close Coin Detail
  const coinDetailClose = () => {
    setSelectedCoin(null);
    setCoinModalOpened(false);
    // dispatch(coinGetListReset())
    dispatch(getTokensOwnedFM(assetType, false, false, -1));
    //dispatch(coinGetList(assetType, false, true, false, null));
  };

  useEffect(() => {
    const type =
      assetType === "token" ? assetsType.token.name : assetsType.goods.name;
    setAssetType(type);
    dispatch(getTokensOwnedFM(type, false, false, -1));
    //dispatch(coinGetList(type, false, true, false, null));
  }, [currentProfile]);

  useEffect(() => {
    if (collectionSelected && assetType === "nft") {
      logger.info("collectionSelected", collectionSelected);
      // dispatch(nftGetListFromCollection(collectionSelected, page));
    }
  }, [collectionSelected]);

  let walletRender;
  let walletFilter = (
    <Tabs
      value={assetType}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="secondary"
      textColor="secondary"
    >
      <Tab
        key={assetsType.token.id}
        value={assetsType.token.name}
        label={t(assetsType.token.name)}
        icon={assetsType.token.icon}
      />
      <Tab
        key={assetsType.goods.id}
        value={assetsType.goods.name}
        label={t(assetsType.goods.name)}
        icon={assetsType.goods.icon}
      />
      <Tab
        key={assetsType.nft.id}
        value={assetsType.nft.name}
        label={t(assetsType.nft.name)}
        icon={assetsType.nft.icon}
        onClick={() => dispatch(collectionResetSelected())}
      />
    </Tabs>
  );
  if (nftLoading) {
    logger.info("[LOADING COLLECTION LIST]");
    walletRender = (
      <>
        <Loading withLoader={true} title={t("waitCoinLoad")} />
      </>
    );
  } else if (collectionSelected && nftList) {
    walletRender = (
      <>
        <NftList nftList={nftList}></NftList>
      </>
    );
  } else if (assetType === "nft") {
    if (collectionList.length > 0) {
      logger.info("[COLLECTION LIST] ->", collectionList);
      walletRender = (
        <>
          <CollectionList collectionList={collectionList} />
        </>
      );
    } else {
      walletRender = (
        <SplashScreen
          title={t("NftCreateForm:noNftCollectionTitle")}
          avatarProps={{ big: true, warning: true }}
        >
          <div>{t("createQuestion")}</div>
          <div>
            <Button
              className={classes.buttons}
              variant="contained"
              onClick={() => history.push("/collectionCreate")}
            >
              {t("NftCreateForm:createNewCollection")}
            </Button>
          </div>
        </SplashScreen>
      );
    }
  } else if (coinLoading) {
    logger.info("[LOADING COIN LIST]");
    walletRender = (
      <>
        <Loading withLoader={true} title={t("waitCoinLoad")} />
      </>
    );
  } else if (coinList && coinList.length !== 0) {
    walletRender = (
      <>
        {/* FIXME - SEARCH REMOVED TEMPORARILY
                       <SearchField
                        placeholder= {t('searchFieldPlaceholder')}
                        searchHandler={(event) => updateSearchInput(event.target.value)}
                        value={searchInput}
                />*/}
        <Divider />
        <CoinList
          coinList={coinList}
          handleSelect={coinDetailOpen}
          showPayButton={true}
          coinLoading={coinLoading}
          withRadio={false}
          selected={undefined}
        />
        {selectedCoin ? (
          <SlideModal
            open={coinModalOpened}
            handleClose={coinDetailClose}
            title={`${selectedCoin.name} | ${t("modalTitleDetails")}`}
          >
            <MyWalletDetails
              selectedCoin={selectedCoin}
              handleClose={coinDetailClose}
            />
          </SlideModal>
        ) : null}
      </>
    );
  } else if (coinList && coinList.length === 0) {
    const createButtonText =
      assetType === assetsType.token.name
        ? t("createNewCoin")
        : t("createNewCoupon");
    const titleText =
      assetType === assetsType.token.name
        ? t("noCoinsTitle")
        : t("noCouponsTitle");
    walletRender = (
      <SplashScreen
        title={titleText}
        avatarProps={{ big: true, warning: true }}
      >
        <div>{t("createQuestion")}</div>
        <div>
          <Button
            className={classes.buttons}
            variant="contained"
            onClick={handleCreateButton}
          >
            {createButtonText}
          </Button>
        </div>
      </SplashScreen>
    );
  }
  return (
    <>
      {walletFilter}
      {walletRender}
    </>
  );
};

export default MyWallet;
