import Paper from '@material-ui/core/Paper'
import { Box, Chip, Grid, Theme, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import { CrwdslCardType, DaoContact } from '../MapUtils'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import './CardDetails.css'

import logo from '../../../assets/img/logo/240x240.png'
import { useTranslation } from 'react-i18next'
import config from '../../../config'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import { handleBottomMenuIndexChange } from '../../../store/slices/uiSlice'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

type CardDetailsPropsType = {
    contact: CrwdslCardType
    closeCard: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '100%',
            borderRadius: 20,
            marginBottom: '0.5vh',
            marginLeft: '0.5vh',
        },
        detailPaper: {
            padding: theme.spacing(2),
            margin: 'auto',
            borderRadius: 20,
        },
        image: {
            width: 280,
            height: 200,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        cardButtonPay: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
            },
        },
        cardButtonClose: {
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        },
        cardDownloadManifest: {
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                backgroundColor: 'primary',
                color: 'white',
            },
        },
    })
)

const bigImgStyle = {
    width: 280,
    height: 200,
}

const smallImgStyle = {
    height: 110,
}

const CrowdsaleCard = (props: CardDetailsPropsType) => {
    const {contact, closeCard } = props
    const classes = useStyles()
    const { t } = useTranslation(['DaoCreateForm', 'Map', 'Common'])
    const dispatch = useAppDispatch()
    const [imgStyle, setImgStyle] = useState<any>(null)

    const status = () => {
        if(contact.status === 'LOCKED'){
            return 'yellow'
        }
        else if(contact.status === 'OPEN'){
            return 'green'
        }
        else if(contact.status === 'CLOSED'){
            return 'red'
        }
        else{
            return 'red'
        }
    }

    useEffect(() => {
        if (window.innerHeight < 760) {
            setImgStyle(smallImgStyle)
        } else {
            setImgStyle(bigImgStyle)
        }
    }, [window.innerHeight])

    return (
        <Grid
            container
            xs={12}
            sm={5}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-end'
            style={{ position: 'absolute', bottom: 0, width: '90%' }}
            spacing={1}
        >
            <Grid item>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent='center'
                        alignItems='flex-start'
                    >
                        <Grid item>
                            <Grid style={imgStyle}>
                                {contact.props.resources &&
                                contact.props.resources.length > 0 ? (
                                    <img
                                        className={classes.img}
                                        alt={contact.props.resources[0].name}
                                        src={
                                            config.network.resources.url +
                                            contact.props.resources[0].fileId
                                        }
                                    />
                                ) : (
                                    <img
                                        className={classes.img}
                                        alt={'default'}
                                        src={logo}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                xs
                                container
                                direction='column'
                                spacing={2}
                            >
                                <Grid item xs>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        style={{ marginTop: '10px' }}
                                    >
                                        {contact.title}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='textPrimary'
                                        style={{ marginTop: '15px' }}
                                    >
                                                {t('Map:creator')}: {contact.ownerData?.first_name} {contact.ownerData?.last_name}
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='textPrimary'
                                        style={{ marginTop: '15px' }}
                                    >
                                        {t('Map:status')}: {<Chip style={{backgroundColor:'white'}} label={contact.status} icon={<FiberManualRecordIcon fontSize='large' style={{color: status()}}/>} />}
                                    </Typography>
                                    {contact.description !== '' ? (
                                        <Box
                                            style={{
                                                height: 120,
                                                maxWidth: 400,
                                                overflowY: 'scroll',
                                                marginTop: '15px',
                                            }}
                                        >
                                                    <Typography
                                                        gutterBottom
                                                        variant='h6'
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {t(
                                                            'Map:daoDescription'
                                                        )}
                                                        :
                                                    </Typography>
                                                    <Typography
                                                        variant='body1'
                                                        gutterBottom
                                                    >
                                                        {
                                                            contact.description
                                                        }
                                                    </Typography>
                                        </Box>
                                    ) : null}
                                </Grid>
                                <Grid item>
                                    <Paper
                                        elevation={4}
                                        style={{
                                            marginTop: '15px',
                                            backgroundColor: '#f1f0ea',
                                            boxShadow: '10 10 10 10 #000',
                                            padding: 2,
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            color='textPrimary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:findus')}: {contact.props.name}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:address')}:{' '}
                                            {contact.props.address}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            color='textSecondary'
                                            style={{
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {t('Map:owner')}:{' '}
                                            {contact.props.owner.display_name}
                                        </Typography>
                                    </Paper>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Grid item>
                                            <Button
                                                className={
                                                    classes.cardButtonPay
                                                }
                                                style={{ marginTop: '15px' }}
                                                size='small'
                                                variant={'outlined'}
                                                onClick={() => {
                                                    closeCard()
                                                    dispatch(handleBottomMenuIndexChange({index: 2}))
                                                }}
                                                color={'primary'}
                                                
                                            >
                                                <Typography variant='subtitle1'>
                                                    VAI
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className={
                                                    classes.cardButtonClose
                                                }
                                                style={{
                                                    marginTop: '15px',
                                                    marginLeft: '15px',
                                                }}
                                                size='small'
                                                variant={'outlined'}
                                                onClick={() => {
                                                    closeCard()
                                                }}
                                                color={'secondary'}
                                            >
                                                <Typography variant='subtitle1'>
                                                    {t('Common:close')}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default CrowdsaleCard
